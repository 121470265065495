@use './src/styles/BO9-theme-variables.scss' as *;
@use '../base/mixins' as *;

.BO9-form {
  label:not(.mat-checkbox-layout) {
    margin: 0 0 10px 22px;
    color: $BO9-secondary-gray;
    font-size: 13px;
    font-weight: 500;
  }

.mat-select-panel {
  max-height: 512px !important;
}
  .mat-form-field-appearance-outline {
    &.mat-form-field {
      display: block;
      height: 50px;
      margin-bottom: 20px;
    }
    .mat-form-field-wrapper {
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .mat-form-field-infix {
      width: auto;
      padding: 0;
      border: 0;
    }
    .mat-form-field-label-wrapper {
      display: none;
    }
    .mat-input-element,
    .mat-select {
      height: 50px;
      font-size: 15px;
      font-weight: normal;
      color: $BO9-gray-light;
    }
    .mat-form-field-flex,
    .mat-select {
      @include display-flex(row);
      align-items: center;
      margin-top: 0;
    }
    .mat-form-field-outline {
      height: 50px;
      top: 0;
      > div {
        border-width: 2px;
      }
    }
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      padding: 0;
      color: $BO9-gray;
      font-size: 15px;
    }
    .mat-select-trigger {
      flex: 1;
    }
    .mat-select-value,
    .mat-input-element {
      font-size: 15px;
      font-weight: normal;
      color: $BO9-gray-light;
    }
    .mat-select-arrow-wrapper {
      transform: none;
    }
  }
  .mat-checkbox,
  .mat-checkbox-inner-container {
    width: 25px;
    height: 25px;
    display: block;
    margin: auto;
  }
  .mat-checkbox {
    &.mat-checkbox-checked .mat-checkbox-background {
      background-color: $BO9-blue;
    }
    .mat-checkbox-layout {
      display: block;
    }
    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: 8px;
    }
  }
  .left-form-field {
    margin-right: 20px;
  }
}
