my-slide-panel {
    max-width: 1800px;
    height: inherit;
  
    div {
      font: 14px sans-serif;
      height: 100%;
  
     
      align-items: flex-start;
      justify-content: left;
    }
  }
  
 ::ng-deep my-slide-panel .panes {
    box-sizing: border-box;
  }
  
  
  [leftPane]  { background-color: #d3cece; }
  [centerPane]  { background-color: #d6d6d4; }
  [rightPane] { background-color: #f3f3df; }
  