@use './src/styles/BO9-theme-variables.scss' as *;
@use '../base/mixins' as *;

.BO9-message-alert {
  background-color: #CC0000;
  font-size: 13px;
  color: #FFF;
  padding: 15px 30px;
  margin: 0 -16px;
}
.BO9-message-alert a {
  color: #FFF;
  text-decoration: none;
  border-bottom: 2px solid rgba(255,255,255,0.33);
}
.BO9-message-active {
  background-color: $BO9-tint-producer;
}
.BO9-message-preview-wrapper {
  border-bottom: 2px solid rgba(0,0,0,0.05);
}
.BO9-message-preview {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 20px;
  align-items: center;
  min-height: 44px;
  text-decoration: none;
  color: $BO9-drk-gray;
  padding: 0 30px 0 20px;
  margin: 2px 0 2px;
}
.BO9-message-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: calc(100% - 16px);
}
.BO9-message {
  margin: 2px 0;
  display: grid;
  grid-column-gap: 5px;
}
.BO9-message-mine {
  grid-template-columns: 1fr 2fr 34px;
  grid-template-areas:
  "space author-name space-2"
  "space message-content author-avatar";
}
.BO9-message-notme {
  grid-template-columns: 34px 2fr 1fr;
  grid-template-areas:
  "space-2 author-name space"
  "author-avatar message-content space";
}
.BO9-message-author-avatar-wrapper {
  width: 40px;
  align-self: center;
}
.BO9-message-author-avatar {
  grid-area: author-avatar;
  background-color: $BO9-tint-drk-lt;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid $BO9-green;
  align-self: end;
  overflow: hidden;
  // Temp Until IMG
  line-height: 30px;
  font-size: 12px;
  text-align: center;
}
.BO9-message-author-avatar img {
  width: 100%;
}
.BO9-transaction-preview-wrapper .BO9-message-author-avatar {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BO9-transaction-preview-wrapper .BO9-message-author-avatar img {
  width: 23px;
  height: 23px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  background-color: $BO9-drk-gray;
  font-size: 10px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  top: 1px;
  right: -7px;
}
.BO9-message-preview-meta {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.BO9-message-preview-time {
  color: $BO9-tint-drk-lt;
}
.BO9-message-preview-user {
  font-weight: 500;
}
.BO9-message-preview-company {
  color: $BO9-green;
}
.BO9-message-preview-transaction {
  font-weight: 500;
}
.BO9-message-author-name {
  grid-area: author-name;
  font-size: 13px;
  color: #666;
  text-indent: 20px;
  line-height: 25px;
}
.BO9-message-content {
  grid-area: message-content;
  background-color: $BO9-tint-producer;
  padding: 10px 20px 5px;
  &.other-user {
    background-color: $BO9-tint-drk-lt;
  }
}
.BO9-message-text {
  font-size: 15px;
}
.BO9-message-meta {
  color: $BO9-tint-drk-lt;
  font-size: 13px;
  text-align: right;
}
.BO9-message-mine .BO9-message-content {
  border-radius: 24px 24px 0 24px;
}
.BO9-message-notme .BO9-message-content {
  border-radius: 24px 24px 24px 0;
}
// Message Form
.BO9-message-form-wrapper {
  display: grid;
  grid-template-columns: 1fr 130px;
  grid-column-gap: 10px;
  padding-top: 20px;
  padding-right: 20px;
}
.BO9-message-form .mat-form-field-appearance-outline {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.BO9-message-form .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 0 0 0;
  padding: 0;
}
.BO9-message-form .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  border: none;
}
.BO9-message-form .mat-input-element {
  height: 50px;
  line-height: 50px;
  margin-top: 4px;
}
// System Message
.BO9-message-system .BO9-message-content {
  border-radius: $BO9-rounding;
  background-color: $BO9-drk-gray;
  color: #FFF;
  margin: 20px 40px;
}
.BO9-message-system .BO9-message-meta {
  color: $BO9-tint-lght;
}
