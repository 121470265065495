@use '../BO9-theme-variables.scss' as *;
// Scoll Content
.BO9-content-scroll {
  height: calc(100% - 22px);
  overflow: auto;
}
// Scroll Width
.BO9-content-scroll::-webkit-scrollbar {
  width: 10px;
}
// Scroll Track
.BO9-content-scroll::-webkit-scrollbar-track {
  background: $BO9-lt-gray;
  border-bottom-right-radius: $BO9-rounding;
}
// Scroll Handle
.BO9-content-scroll::-webkit-scrollbar-thumb {
  background: $BO9-gray;
  border-radius: $BO9-rounding;
}
// Scroll Handle on hover
.BO9-content-scroll::-webkit-scrollbar-thumb:hover {
  background: $BO9-drk-gray;
}
