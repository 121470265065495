@use "@angular/material/prebuilt-themes/indigo-pink.css" as *;

// Base
@use "vendor.scss" as *;

@use "base/mixins.scss" as *;
@use "base/base.scss" as *;
@use "base/icons.scss" as *;
@use "base/buttons.scss" as *;
@use "base/scroll.scss" as *;
@use "base/tables.scss" as *;
@use "./BO9-theme-variables.scss" as *;

// Components
@use "components/comp-table.scss" as *;
@use "components/comp-tabs.scss" as *;
@use "components/comp-cards.scss" as *;
@use "components/comp-form.scss" as *;
@use "components/comp-messages.scss" as *;
@use "components/comp-people.scss" as *;
@use "components/comp-text-editor.scss" as *;
@use "components/comp-loader-skeleton.scss" as *;
@use "components/comp-slide-panel.scss" as *;

@use "video/videogular.css" as *;





.mat-mdc-paginator-container {
        width: 40% !important;
}

.myPanelClass{
    margin-top: 60px !important; 
}

.bo9mat {
    border-radius: 8px;
    box-shadow: 0 2px 2px #00000026, 0 0 1px #0000002b, 0 4px 8px #00000012;
    margin: 10px;
    padding: 10px 0 10px 10px;
}


.profile-tabs .mat-tab-label-content {
    .my-tab-color {
        padding-right: 15px;
    }
    .my-tab-color {
        &.my-tab-color-1 {
            background-color: $BO9-tab-background-1;
            color: $BO9-tab-color-1;
        }
    }
  .my-tab-color {
        &.my-tab-color-2 {
            background-color: $BO9-tab-background-2;
            color: $BO9-tab-color-2;
        }
    }
  .my-tab-color {
        &.my-tab-color-3 {
            background-color: $BO9-tab-background-3;
            color: $BO9-tab-color-3;
        }
    }
  .my-tab-color {
        &.my-tab-color-4 {
            background-color: $BO9-tab-background-4;
            color: $BO9-tab-color-4;
        }
    }
    .my-tab-color {
      &.my-tab-color-5 {
          background-color: $BO9-tab-background-5;
          color: $BO9-tab-color-5;
      }
  }
  .my-tab-color {
    &.my-tab-color-6 {
        background-color: $BO9-tab-background-6;
        color: $BO9-tab-color-6;
    }
  }
  }

