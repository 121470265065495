@mixin display-flex($direction: column) {
  display: flex;
  flex-direction: $direction;
}

@mixin breakpoint($size) {
  @if $size == large {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else if $size == medium {
    @media (min-width: 960px) and (max-width: 1200px) {
      @content;
    }
  }
  @else if $size == small {
    @media (min-width: 320px) and (max-width: 960px) {
      @content;
    }
  }
}

@mixin breakpoint-atleast($point) {
  @if $point == tablet {
    @media (min-width: 668px) {
      @content;
    }
  }
  @if $point == laptop {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @if $point == desktop {
    @media (min-width: 1440px) {
      @content;
    }
  }
}

@mixin breakpoint-atmost($point) {
  @if $point == phone {
    @media (max-width: 880px) {
      @content;
    }
  }
  @if $point == tablet {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @if $point == laptop {
    @media (max-width: 1440px) {
      @content;
    }
  }
}
