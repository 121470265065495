@use '../BO9-theme-variables.scss' as *;

.BO9-icons-m,
.BO9-organization-people-chat {
  mask: url("/assets/BO9-icon-sprite.svg");
  background: $BO9-tint-drk-lt;
  mask-size: 29px 450px;
  mask-position: 0px 0px;
  width: 29px;
  height: 29px;
  margin-right: 10px;
  transition: background 2s;
  display: inline-block;
  text-indent: -9999px;
  &.icon-m-dashboard {
    mask-position: 0px -27px;
  }
  &.icon-m-messages {
    mask-position: 0px -56px;
  }
  &.icon-m-community {
    mask-position: 0px -83px;
  }
  &.icon-m-registery {
    mask-position: 0px -112px;
  }
  &.icon-m-portfolio {
    mask-position: 0px -139px;
  }
  &.icon-m-support {
    mask-position: 0px -252px;
  }
  &.icon-m-admin {
    mask-position: 0px -365px;
  }
  &.icon-m-people {
    mask-position: 0px -279px;
    background: $BO9-green;
  }
  &.icon-m-transactions {
    mask-position: 0px -112px;
    background: $BO9-drk-gray;
  }
  &.icon-m-certificate {
    mask-position: 0px -308px;
    background: #ffffff;
  }
  &.icon-m-market {
    mask-position: 0px -225px;
  }
  &.icon-custody {
    mask-position: 0px -395px;
  }
}
.BO9-organization-people-chat {
  mask-position: 0px -56px;
}
.sidebar-active-link .BO9-icons-m {
  background: $BO9-drk-gray;
}

.material-icons {
  margin-right: 10px;
}
