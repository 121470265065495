@use './src/styles/BO9-theme-variables.scss' as *;
@use '../base/mixins' as *;

// Cards
body .mat-card {
  max-width: 1400px;
  @include display-flex;
  padding: 20px 20px 20px 20px;
  &:not([class*='mat-elevation-z']) {
    border-radius: $BO9-rounding;
    box-shadow: $BO9-box-shadow;
    margin: 10px 10px 10px 10px;
    @include breakpoint-atmost(tablet) {
      padding: 10px 0 10px 10px;
    }
  }
  .mat-card-header-text {
    margin: 0;
  }
  .BO9-sub-card {
    border-radius: $BO9-rounding;
    box-shadow: $BO9-box-shadow-sub;
    overflow: hidden;
    padding: 16px;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    header {
      flex: 0 0 auto;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid $BO9-lt-gray;
      h3 {
        margin: 0;
        color: $BO9-secondary-gray;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
  .BO9-card-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: $BO9-drk-gray;
    margin-bottom: 13px;
    @include breakpoint-atmost(tablet) {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  > .BO9-card-title {
    margin-bottom: 5px;
    padding-bottom: 8px;
    border-bottom: 2px solid $BO9-lt-gray;
  }
  .BO9-card-sub-title {
    font-weight: 500;
    color: $BO9-drk-gray;
    line-height: 1.7;
  }
  app-communityupdates {
    height: 100%;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
