@use './src/styles/BO9-theme-variables.scss' as *;
@use '../base/mixins' as *;

$base-color: #F4F4F4;
$shine-color: rgba(235, 235, 235, .8);
$animation-duration: 2.0s;
$avatar-offset: 68;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}

.message {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  .avatar {
    @include background-gradient;
    width: 26px;
    height: 26px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 8px 0 0;
    animation: shine-avatar $animation-duration infinite ease-out;
  }
  .line {
    @include background-gradient;
    height: 12px;
    flex: 1;
    border-radius: 7px;
    animation: shine-lines $animation-duration infinite ease-out;
  }
}

.table-row {
  width: 100%;
  height: 48px;
  background-color: #fff;
  animation: shine-lines $animation-duration infinite ease-in-out;
  &:nth-child(even) {
    @include background-gradient;
    background-color: #ccc;
  }
}

.loader-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
}

.loader-small-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.transaction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 230px;
  border-radius: $BO9-rounding;
  box-shadow: $BO9-box-shadow;
  .top {
    @include background-gradient;
    width: 100%;
    height: 80px;
    border-radius: 8px 8px 0 0;
    animation: shine-lines $animation-duration infinite ease-out;
  }
  .line,
  .bottom-line {
    @include background-gradient;
    width: 80%;
    height: 12px;
    margin: 10px 10px 0;
    border-radius: 7px;
    animation: shine-lines $animation-duration infinite ease-out;
  }
  .bottom-line {
    width: 60%;
    height: 8px;
  }
}

.inventory {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  height: 209px;
  .top {
    @include background-gradient;
    width: 100%;
    height: 80px;
    animation: shine-lines $animation-duration infinite ease-out;
  }
  .line,
  .bottom-line {
    @include background-gradient;
    width: 80%;
    height: 12px;
    margin: 15px 0;
    border-radius: 7px;
    animation: shine-lines $animation-duration infinite ease-out;
  }
  .bottom-line {
    width: 60%;
    height: 8px;
  }
}

.custody-loader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  border-radius: $BO9-rounding;
  box-shadow: $BO9-box-shadow;
  &.docs-loader{
    @include display-flex;
    align-items: center;
    .BO9-sub-card {
      width: 85%;
      height: 128px;
      margin: 10px auto 0;
    }
  }
  .top {
    @include background-gradient;
    width: 100%;
    height: 51px;
    border-radius: 8px 8px 0 0;
    animation: shine-lines $animation-duration infinite ease-out;
  }
  .line,
  .bottom-line {
    @include background-gradient;
    width: 80%;
    height: 12px;
    margin: 27px 10px 0;
    border-radius: 7px;
    animation: shine-lines $animation-duration infinite ease-out;
  }
  .bottom-line {
    width: 50%;
    height: 8px;
    margin-top: 20px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -600px;
  }
  40%,
  100% {
    background-position: 1340px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%, 100% {
    background-position: 140px + $avatar-offset;
  }
}
