@use './src/styles/BO9-theme-variables.scss' as *;
@use '../base/mixins' as *;

* {
  box-sizing: border-box;
}
html, body {
  height: 100%;
}

body {
  margin: 0;
  background-color: $BO9-white;
  text-rendering: geometricPrecision;
  font-family: "IBM Plex Sans", Helvetica Neue, Helvetica, sans-serif;
  .mat-drawer-container,
  .mat-drawer-content {
    flex: 1;
    background-color: transparent;
  }
  .mat-drawer-content {
    display: flex;
    flex-direction: column;
  }
  .app-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

// Links Default
a {
  color: $BO9-drk-gray;
  text-decoration: none;
  &:hover {
    text-decoration-color: $BO9-drk-gray;
    text-decoration-color: currentColor;
  }
}

textarea:focus, input:focus {
  outline: none;
}

// Type Colors
.BO9-producer-text {
  color: $BO9-green;
}
.BO9-company-text {
  color: $BO9-blue;
}

// map box
.mapboxgl-canvas {
  min-height: 45vh !important;
  position: relative !important;
}

.layout-row {
  @include display-flex(row);
}

.layout-column {
  @include display-flex;
}

.flex {
  flex: 1;
}

