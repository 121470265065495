@use '../BO9-theme-variables.scss' as *;

body .BO9-table {
  width: 100%;
  margin: 20px 0 0;
  font-size: 13px;
  border-collapse: collapse;
  .mat-row,
  .mat-header-row {
    height: 70px;
    border-bottom: 2px solid $BO9-lt-gray;
    padding: 0 20px;
  }
  th {
    color: $BO9-gray-light;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  tr.mat-row:nth-child(even) {
    background-color: $BO9-lt-gray;
  }
  td {
    color: $BO9-secondary-gray;
    font-weight: 500;
    &.mat-cell {
      padding: 0 5px 0 15px;
      font-size: 13px;
    }
    .BO9-buyer-link {
      color: $BO9-blue;
      font-weight: 500;
    }
  }
  thead .mat-header-row {
    border: 0;
  }
  th {
    padding: 0;
    border: 0;
    &:first-of-type {
      padding: 0;
    }
    &:last-of-type {
      padding: 0;
    }
  }
  th > div {
    height: 56px;
    padding: 0 5px 0 15px;
    border: 2px solid $BO9-lt-gray;
    border-left: 0;
  }
  th:last-child > div {
    border-right: 0;
  }
  .mat-cell {
    border-right: 2px solid $BO9-lt-gray;
    &:last-child {
      border-right: none;
    }
  }
  .BO9-table-row-link {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .BO9-table-row-icon-wrapper {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    padding: 5px;
    overflow: hidden;
    background-color: #ffffff;
  }
  img {
    display: block;
    width: 15px;
    height: 15px;
  }
}
