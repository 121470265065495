@use '../BO9-theme-variables.scss' as *;
@use 'sass:color';

.BO9-tabs-container {
  &.mat-mdc-tab-group {
    flex: 1;
  }
  .mat-mdc-tab-label-container,
  .mat-mdc-tab-header {
    top: -28px;
    border: none;
  }
  .mat-ink-bar {
    display: none;
  }
  .BO9-tab-wrapper,
  .mat-mdc-tab-labels {
    position: relative;
    height: 60px;
    padding-left: 10px;
  }
  .BO9-tab {
    position: relative;
    top: -28px;
  }
  .mat-mdc-tab-label,
  .BO9-tab {
    display: inline-block;
    height: 50px;
    font-weight: 500;
    box-shadow: $BO9-box-shadow-sub;
    margin: 8px 10px 0 0;
    padding: 15px 30px 0;
    font-size: 18px;
    border-bottom-left-radius: $BO9-rounding;
    border-bottom-right-radius: $BO9-rounding;
    background-color: #fff;
    transition: padding 600ms, margin 600ms, background-color 600ms, color 600ms;
    min-width: auto;
    opacity: 1;
    &:hover {
      background-color: $BO9-lt-gray;
    }
    &.mat-mdc-tab-label-active,
    &.BO9-tab-active {
      height: 100%;
      margin-top: 0;
      padding-top: 25px;
      color: #FFF;
      background-color: $BO9-drk-gray;
      transition: padding 300ms, margin 300ms, background-color 300ms, color 300ms;
    }
  }
  .mat-mdc-tab-body-wrapper {
    flex: 1;
    transition: none;
  }
  .mat-mdc-tab-body.mat-mdc-tab-body-active,
  .mat-mdc-tab-body-content {
    overflow: visible;
    width: 100%;
  }
}

.mat-mdc-tab-group[data-css='partner'] .mat-mdc-tab-label{
	// border: 3px solid #fff;
	// border-bottom: 0;
	border-radius: 12px 12px 0 0;
	opacity: 0.7;
	margin-right: 15px;
	padding-top: 10px;
	&[aria-selected = 'true']{
		opacity: 1;
		padding-bottom: 5px;
		padding-top: 0;
		border-bottom: 3px solid #000;
	}
}

.mat-mdc-tab-group[data-css='partner'] .mat-mdc-tab-body-wrapper{
	padding: 20px 0;
}

@media only screen and (max-width: 900px) {
  .mat-mdc-tab-group[data-css='partner'] {
    .mat-mdc-tab-label{
    max-width: 80px !important;
    min-width: 80px !important;
    }
    .mat-mdc-tab-label-content {
      font-size: 8px;
    }
  }
}
.mat-mdc-tab-group[data-css='partner'] {
	.mat-mdc-tab-label:nth-child(1) {
		background-color: $BO9-tab-background-1;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-1, $lightness: 75% );
	}
	.mat-mdc-tab-label:nth-child(2)  {
		background-color: $BO9-tab-background-2;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-2, $lightness: 75% );
	}
	.mat-mdc-tab-label:nth-child(3) {
		background-color: $BO9-tab-background-3;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-3, $lightness: 75% );
	}
	.mat-mdc-tab-label:nth-child(4) {
		background-color: $BO9-tab-background-4;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-4, $lightness:75% );
	}
	.mat-mdc-tab-label:nth-child(5) {
		background-color: $BO9-tab-background-5;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-5, $lightness: 75% );
	}
	.mat-mdc-tab-label:nth-child(6) {
		background-color: $BO9-tab-background-6;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-6, $lightness:75% );
	}
	.mat-mdc-tab-label:nth-child(7) {
		background-color: $BO9-tab-background-7;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-7, $lightness: 75% );
	}
	.mat-mdc-tab-label:nth-child(8) {
		background-color: $BO9-tab-background-8;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-8, $lightness: 75% );
	}
#mat-tab-label-0-1,#mat-tab-label-1-1,#mat-tab-label-2-1,#mat-tab-label-3-1, #mat-tab-label-4-1, #mat-tab-label-5-1, #mat-tab-label-6-1{
  border-radius: 12px 12px 0 0;
  opacity: .7;
  margin-right: 15px;
  padding-top: 10px;
		background-color: $BO9-tab-background-1;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-1, $lightness:75% );
	}
  #mat-tab-label-0-2,#mat-tab-label-1-2,#mat-tab-label-2-2,#mat-tab-label-3-2, #mat-tab-label-4-2, #mat-tab-label-5-2, #mat-tab-label-6-2 {
    border-radius: 12px 12px 0 0;
    opacity: .7;
    margin-right: 15px;
    padding-top: 10px;
		background-color: $BO9-tab-background-2;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-2, $lightness: 75% );
	}
  #mat-tab-label-0-3,#mat-tab-label-1-3,#mat-tab-label-2-3,#mat-tab-label-3-3, #mat-tab-label-4-3, #mat-tab-label-5-3, #mat-tab-label-6-3{
    border-radius: 12px 12px 0 0;
    opacity: .7;
    margin-right: 15px;
    padding-top: 10px;
		background-color: $BO9-tab-background-3;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-3, $lightness:75% );
	}
  #mat-tab-label-0-4,#mat-tab-label-1-4,#mat-tab-label-2-4,#mat-tab-label-3-4, #mat-tab-label-4-4, #mat-tab-label-5-4, #mat-tab-label-6-4 {
    border-radius: 12px 12px 0 0;
    opacity: .7;
    margin-right: 15px;
    padding-top: 10px;
		background-color: $BO9-tab-background-4;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-4, $lightness: 75% );
	}
  #mat-tab-label-0-5,#mat-tab-label-1-5,#mat-tab-label-2-5,#mat-tab-label-3-5, #mat-tab-label-4-5, #mat-tab-label-5-5, #mat-tab-label-6-5  {
    border-radius: 12px 12px 0 0;
    opacity: .7;
    margin-right: 15px;
    padding-top: 10px;
		background-color: $BO9-tab-background-5;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-5, $lightness:75% );
	}
  #mat-tab-label-0-6,#mat-tab-label-1-6,#mat-tab-label-2-6,#mat-tab-label-3-6, #mat-tab-label-4-6, #mat-tab-label-5-6, #mat-tab-label-6-6  {
    border-radius: 12px 12px 0 0;
		background-color: $BO9-tab-background-6;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-6, $lightness:75% );
	}
  #mat-tab-label-0-7,#mat-tab-label-1-7,#mat-tab-label-2-7,#mat-tab-label-3-7, #mat-tab-label-4-7, #mat-tab-label-5-7, #mat-tab-label-6-7 {
    border-radius: 12px 12px 0 0;
    opacity: .7;
    margin-right: 15px;
    padding-top: 10px;
		background-color: $BO9-tab-background-7;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-7, $lightness:75% );
	}
  #mat-tab-label-0-0,#mat-tab-label-1-0,#mat-tab-label-2-0,#mat-tab-label-3-0, #mat-tab-label-4-0, #mat-tab-label-5-0, #mat-tab-label-6-0  {
    border-radius: 12px 12px 0 0;
    opacity: .7;
    margin-right: 15px;
    padding-top: 10px;
		background-color: $BO9-tab-background-8;
		box-shadow: inset 0 0 0px 1px color.adjust($BO9-tab-color-8, $lightness: 75% );
	}


	.material-icons{
		vertical-align: middle;
		height: 28px;
		width: 28px;
		margin-right:5px;
	}
}
