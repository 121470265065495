@use '../../styles/BO9-theme-variables.scss' as *;

// Buttons
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-flat-button:not([class*='mat-elevation-z']) {
  background-color: $BO9-button-default;
  border-radius: $BO9-rounding;
  color: #FFF;
}
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: 15px;
  font-weight: 300;
  background-color: lightgray;
}
.mat-stroked-button:not([disabled]),
.mat-stroked-button:not([class*='mat-elevation-z']) {
  border-radius: $BO9-rounding;
  border: 2px solid $BO9-gray;
  color: $BO9-drk-gray;
}
.mat-flat-button,
.mat-stroked-button {
  line-height: 50px;
}
.mat-submit-button {
  background-color: white;
  border-radius: $BO9-rounding !important;
  border: 2px solid $BO9-gray !important;
}
