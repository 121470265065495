@use './src/styles/BO9-theme-variables.scss' as *;
@use '../base/mixins' as *;
.BO9-organization-people-meta {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.BO9-organization-people-name {
  font-weight: 500;
  color: $BO9-green;
}
.BO9-organization-people-chat-wrapper {
  justify-self: end;
  align-items: center;
  display: flex;
}
